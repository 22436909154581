import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"
import { FaCar, FaStore, FaPhone } from "react-icons/fa"
const ButtonHolder = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 50px;
  margin-bottom: 20px;

  a {
    margin: 0px 10px;
    padding: 8px 15px;
    border-radius: 10px;
    background-color: #e51937;
    color: white;
    font-size: 20px;
    text-decoration: none;
    border: 3px solid black;
  }

  .fa {
    margin-left: 10px;
    position: relative;
    top: 3px;
  }
`

const OrderButtons = props => {
  const data = useStaticQuery(graphql`
    query OrderButtonQuery {
      allVenue {
        nodes {
          ID_clients
          phone
          externalServices {
            name
            url
          }
        }
      }
    }
  `)

  const ID_clients = props.ID_clients ?? "56"
  const showPhone = props.showPhone ?? false
  const venues = data.allVenue.nodes ?? []

  let deliveryUrl = `//mamaspizza.order.online/business/-28368/?delivery=true`
  const venue = venues.find(v => v.ID_clients == ID_clients) ?? {}

  if (ID_clients !== "56") {
    const externalServices = venue.externalServices ?? []
    deliveryUrl =
      externalServices.find(s => s.name === "doordash-storefront")?.url ?? ""
  }

  return (
    <ButtonHolder>
      {showPhone && venue.phone && (
        <a href={`tel:${venue.phone}`}>
          <span>{venue.phone}</span>
          <FaPhone className="fa"></FaPhone>
        </a>
      )}
      <a
        key="mamas"
        rel="noopener noreferrer"
        aria-label="Mamas Pickup"
        target="_blank"
        href={`//order.mamaspizzas.net`}
      >
        <span>Pickup</span>
        <FaStore className="fa"></FaStore>
      </a>
      <a
        key="mamas"
        rel="noopener noreferrer"
        aria-label="Mamas Delivery"
        target="_blank"
        href={deliveryUrl}
      >
        <span>Delivery</span>
        <FaCar className="fa"></FaCar>
      </a>
    </ButtonHolder>
  )
}

export default OrderButtons
