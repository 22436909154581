import React from "react"

const formatTime = timeIn => {
  const timeArray = timeIn.split(":")
  let timeOut = ""

  if (+timeArray[0] > 12) {
    timeOut = (+timeArray[0] - 12).toString() + ":" + timeArray[1]
  } else {
    timeOut = timeIn
  }

  if (+timeArray[0] > 11) timeOut = timeOut + " PM"
  else timeOut = timeOut + " AM"

  return timeOut
}

const days = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
]

export default props => {
  return (
    <>
      {props.hours.map(hour => (
        <div>
          {days[hour.DayOfWeek]}: {formatTime(hour.OpenTime)} -{" "}
          {formatTime(hour.CloseTime)}
        </div>
      ))}
    </>
  )
}
